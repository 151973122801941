<template>
  <CContainer fluid class="mainTableContainer">
    <CRow alignVertical="center" alignHorizontal="center">
      <CCol lg="12" class="mainTableColumn">
        <CButton color="primary" @click="createTemplate()"
        >Create</CButton
        >
      </CCol>
      <CCol lg="12" class="mainTableColumn">
        <CDataTable
            :noItemsView="{
            noResults: 'no items found',
            noItems: 'no items',
          }"
            :hover="true"
            :striped="true"
            :items-per-page="20"
            columnFilter
            sorter
            pagination
            :items="templates"
            :clickableRows="true"
            :loading="loading"
            :fields="fields"
            size="sm"
            :tableFilterValue.sync="filter"
            @row-clicked="openTemplateDetail"
        >
          <template #templates="{ item }">
            <td>
              {{ item.templates.length }}
            </td>
          </template>
          <template #options="{ item }">
            <td>
              <Pencil :size="20" @click.stop="editItem(item)"></Pencil>
              <Delete :size="20" @click.stop="deleteItemShow(item.id)"></Delete>
            </td>
          </template>
        </CDataTable>
      </CCol>
    </CRow>
    <DeleteConfirmModal
        :show="showDelete"
        @close="hideDelete"
        @accept="deleteItemConfirm"
    />
    <TemplateModal
        v-if="showModal"
        :item="template"
        @accept="confirmTemplate"
        :show-modal="showModal"
        :event="event"
        @close="closeModal"
    />
  </CContainer>
</template>

<script>
import { CDataTable, CRow, CCol, CContainer, CButton } from "@coreui/vue/src";
import {templateModule} from "@/store/modules/templateModule";
import Pencil from "vue-material-design-icons/Pencil.vue";
import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal.vue"
import Delete from "vue-material-design-icons/Delete.vue";
import TemplateModal from "@/components/modals/TemplateModal";
export default {
  name: "Templates",
  components:{
    CRow, CCol, CContainer, CButton,CDataTable, Pencil, Delete, DeleteConfirmModal, TemplateModal
  },
  data(){
    return{
      deleteID:null,
      showDelete:false,
      loading:false,
      event:"create",
      filter:"",
      template:null,
      defaultTemplate:{
        name:"default name",
        description:"default description",
        images:[],
        hotel_id:null,
        template_category_id:+this.$route.params.templateCategory,
        content:""
      },
      showModal:false,
      fields: [
        { label: "ID", key: "id", sorter: true, filter: true },
        { label: "Name", key: "name", sorter: true, filter: true },
        {
          label: "Description",
          key: "description",
          sorter: true,
          filter: true,
        },
        { label: "Options", key: "options", sorter: false, filter: false },
      ],
    }
  },
  methods:{
    ...templateModule.mapActions(['deleteTemplate','postTemplate','putTemplate','postTemplateByHotelId']),
    closeModal(){
      this.showModal = false;
    },
    async confirmTemplate(){
      if(this.event === 'create'){
        if(this.template.hotel_id){
          await this.postTemplateByHotelId(this.template);
        }else{
          await this.postTemplate(this.template);
        }
      }
      else{
        await this.putTemplate(this.template);
      }
      this.showModal = false;
    },
    editItem(item){
      this.template = JSON.parse(JSON.stringify(item));
      this.template.content = JSON.stringify(item.content);
      this.event = 'edit';
      this.showModal = true;
    },
    deleteItemShow(id){
      this.deleteID = id;
      this.showDelete = true;
    },
    openTemplateDetail(item){
      //
    },
    hideDelete(){
      this.showDelete = false;
    },
    async deleteItemConfirm(){
      await this.deleteTemplate(this.deleteID);
      this.showDelete = false;
    },
    createTemplate(){
      this.template = JSON.parse(JSON.stringify(this.defaultTemplate));
      this.event = 'create';
      this.showModal = true;
    }
  },
  computed:{
    ...templateModule.mapState(['templates']),
  },
  mounted(){
    console.log(this.templates);
  }
}
</script>

<style scoped>
.mainTableColumn{
  margin-bottom:15px
}
</style>
